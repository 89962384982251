import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [

  // Tableau de bord
  {
    path: "",
    title: "Tableau de bord",
    moduleName: "tableau-de-bord",
    iconType: "clock",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    submenu: [
      {
        path: "/tableau-de-bord/pharmacie",
        title: "Pharmacie",
        moduleName: "tableau-de-bord",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "DASHBORD-PHARMACIE",
        submenu: [],
      },
      {
        path: "/tableau-de-bord/tresorerie",
        title: "Trésorerie",
        moduleName: "tableau-de-bord",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "DASHBORD-TRESORERIE",
        submenu: [],
      },
      
    ],
  },
  {
    path: "",
    title: "Etats",
    moduleName: "etats",
    iconType: "clock",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "ETATS",
    submenu: [
      {
        path: "/etats/pharmacie",
        title: "Pharmacie",
        moduleName: "etats",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ETATS_PHARMA",
        submenu: [],
      },
      {
        path: "/etats/tresorerie",
        title: "Trésorie",
        moduleName: "etats",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ETATS_TRESO",
        submenu: [],
      },
      
    ],
  },

  // Gestion administrative
  {
    path: "",
    title: "Gestion administrative",
    moduleName: "gestion-administrative",
    iconType: "clock",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "GEST-ADMINISTRATIVE-NAV",
    submenu: [
      {
        path: "/gestion-administrative/gestion-vacations",
        title: "Gestion des vacations",
        moduleName: "gestion-vacations",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-ADMINISTRATIVE-GESTION-VACATIONS",
        submenu: [
          {
            path: "/gestion-administrative/gestion-vacations/vacataire",
            title: "Vacataires",
            moduleName: "gestion-vacations",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-GESTION-VACATIONS-VACATAIRE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/gestion-vacations/vacations",
            title: "Vacation",
            moduleName: "gestion-vacations",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-GESTION-VACATIONS-VACATION",
            submenu: [],
          },
          {
            path: "/gestion-administrative/gestion-vacations/traitements",
            title: "Traitement honoraires",
            moduleName: "gestion-vacations",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-GESTION-VACATIONS-TRAITEMENT-HONORAIRE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/gestion-vacations/validation",
            title: "Validation honoraires",
            moduleName: "gestion-vacations",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-GESTION-VACATIONS-VALIDATION-HONORAIRE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/gestion-vacations/reglement",
            title: "Règlement honoraires",
            moduleName: "gestion-vacations",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-GESTION-VACATIONS-REGLEMENT-HONORAIRE",
            submenu: [],
          },
        ],
      },
      {
        path: "/gestion-administrative/rdv",
        title: "Rendez-vous",
        moduleName: "rdv",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-ADMINISTRATIVE-PRISE-RENDEZ-VOUS",

        submenu: [
          {
            path: "/gestion-administrative/rdv",
            title: "Prise de rendez-vous",
            moduleName: "rdv",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-PRISE-RDV",
            submenu: [],
          },
          {
            path: "/gestion-administrative/planning/consulter-rdv",
            title: "Consultation de rdv",
            moduleName: "rdv",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "	GEST-ADMINISTRATIVE-CONSULTER-RDV",
            submenu: [],
          },

          {
            path: "/gestion-administrative/liste-rdv",
            title: "Liste des rdv",
            moduleName: "rdv",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-LISTE-RDV",
            submenu: [],
          }

        ],
      },
      {
        path: "/gestion-administrative/planning",
        title: "Planning",
        moduleName: "planning",
        iconType: "calendar",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-ADMINISTRATIVE-PLANNING",
        submenu: [
          {
            path: "/gestion-administrative/planning/planning",
            title: "Création de programme",
            moduleName: "planning",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-PLANNING-ADD",
            submenu: [],
          },
          {
            path: "/gestion-administrative/planning/gestion",
            title: "Disponibilité",
            moduleName: "planning",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-PLANNING-VIEW-DISPONIBILITE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/planning/consulter-planning",
            title: "Consultation programme",
            moduleName: "planning",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            // code: "82209945",
            submenu: [],
          },
          {
            path: "/gestion-administrative/planning/consulter-planning",
            title: "Consultation de rdv",
            moduleName: "planning",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            // code: "82209945",
            submenu: [],
          },
          {
            path: "/gestion-administrative/planning/edition-programme",
            title: "Edition de programme",
            moduleName: "planning",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-PLANNING-EDITION",
            submenu: [],
          }
        ],
      },
      {
        path: "",
        title: "Reclamation",
        moduleName: "reclamation",
        iconType: "clock",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-ADMINISTRATIVE-RECLAMMATION",
        submenu: [
          {
            path: "/gestion-administrative/reclamation/register",
            title: "Enregistrement de plainte",
            moduleName: "reclamation",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-RECLAMMATION-ADD-PLAINTE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/reclamation/imputation-plainte",
            title: "Imputation plainte",
            moduleName: "reclamation",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-RECLAMMATION-IMPUTATION",
            submenu: [],
          },

          {
            path: "/gestion-administrative/reclamation/justificatif-medecin",
            title: "Justification medecin",
            moduleName: "reclamation",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-RECLAMMATION-JUSTIFICATION",
            submenu: [],
          },
          {
            path: "/gestion-administrative/reclamation/analyse",
            title: "Analyse et traitement",
            moduleName: "reclamation",
            iconType: "clock",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-RECLAMMATION-ANALYSE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/reclamation/reglement-plainte",
            title: "Règlement de la plainte",
            moduleName: "reclamation",
            iconType: "clock",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-RECLAMMATION-REGLEMENT-PLAINTE",
            submenu: [],
          },
          {
            path: "/gestion-administrative/reclamation/process",
            title: "Vérification et décisions",
            moduleName: "reclamation",
            iconType: "clock",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "GEST-ADMINISTRATIVE-RECLAMMATION-VERIFIER",
            submenu: [],
          },
        ],
      },
    ],
  },

  // Bureau des entrées
  {
    path: "",
    title: "Bureau des entrées",
    moduleName: "bureau-des-entrees",
    iconType: "users",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "BUREAUX-ENTREE-NAV",
    submenu: [
      {
        path: "/bureau-des-entrees/accueil-reception",
        title: "Reception",
        moduleName: "accueil-reception",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "BUREAUX-ENTREE-RECEPTION",
        submenu: [
          {
            path: "/bureau-des-entrees/accueil-reception/acceuil",
            title: "Accueil",
            moduleName: "accueil-reception",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-RECEPTION-ACCUEIL",
            submenu: [],
          },
            {
              path: "/bureau-des-entrees/accueil-reception/dialyse",
              title: "Dialyse",
              moduleName: "accueil-reception",
              iconType: "list",
              icon: "plus",
              class: "ml-menu",
              groupTitle: false,
              badge: "",
              badgeClass: "",
              code: "BUREAUX-ENTREE-RECEPTION-ACCUEIL",
              submenu: [],
          },
          {
            path: "/bureau-des-entrees/accueil-reception/client",
            title: "Clients",
            moduleName: "accueil-reception",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-RECEPTION-CLIENT",
            submenu: [],
          }
        ],
      },
      {
        path: "/bureau-des-entrees/dialyse",
        title: "Dialyse",
        moduleName: "dialyse",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "BUREAUX-ENTREE-DIALYSE",
        submenu: [
          {
            path: "/bureau-des-entrees/dialyse/historique-requete-dialyse/xQadwWWsa12",
            title: "Requêtes dialyse",
            moduleName: "dialyse",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-DIALYSE-REQUETE",
            submenu: [],
          },
          {
            path: "/bureau-des-entrees/dialyse/seance",
            title: "Séance",
            moduleName: "dialyse",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-DIALYSE-SEANCE",
            submenu: [],
          },{
            path: "/bureau-des-entrees/dialyse/programme",
            title: "Programme",
            moduleName: "dialyse",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-DIALYSE-PROGRAMME",
            submenu: [],
          },
        ],
      },
      {
        path: "/bureau-des-entrees/remboursement",
        title: "Demande Rembours.",
        moduleName: "remboursement",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "BUREAUX-ENTREE-REMBOURSEMENT",
        submenu: [
          {
            path: "/bureau-des-entrees/remboursement/actes-externes",
            title: "Actes externes",
            moduleName: "remboursement",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-REMBOURSEMENT-AE",
            submenu: [],
          },
         
          {
            path: "/bureau-des-entrees/remboursement/pharmacie-vente-prescription",
            title: "Vente Prescription",
            moduleName: "remboursement",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-REMBOURSEMENT-VENTE-PRESCRIPTION",
            submenu: [],
          },
          {
            path: "/bureau-des-entrees/remboursement/pharmacie-vente-direct",
            title: "Vente directe Phcie",
            moduleName: "remboursement",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-REMBOURSEMENT-VENTE-DIRECTE",
            submenu: [],
          }, 
          {
            path: "/bureau-des-entrees/remboursement/pharmacie-vente-bon",
            title: "Vente sur bon",
            moduleName: "remboursement",
            iconType: "list",
            icon: "plus",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BUREAUX-ENTREE-REMBOURSEMENT-VENTE-DIRECTE",
            submenu: [],
          },
        ],
      },
    ],
  },

  // Treso
  {
    path: "/tresorerie/main-tresorerie",
    title: "Trésorerie",
    moduleName: "tresorerie",
    iconType: "money-bill",
    icon: "monetization_on",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "TRESO-NAV",
    submenu: [
      {
        path: "/tresorerie/main-tresorerie/mouvement-caisse",
        title: "Mouvement caisse",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-MOUVEMENT-CAISSE",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/caisse-actes-externes",
        title: "Caisse bureau des entrées",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-CAISSE-BUREAU-ENTREES",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/caisse-pharmacie",
        title: "Caisse pharmacie",
        moduleName: "caisse-pharmacie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-CAISSE-PHRAMACIE",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/remboursement",
        title: "Remboursements",
        moduleName: "caisse-pharmacie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-REMBOURSEMENT",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/reglement-fournisseur",
        title: "Règlement fournisseur",
        moduleName: "caisse-pharmacie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-REGLEMENT-FOURNISSEUR",
        submenu: [],
      },

      {
        path: "/tresorerie/main-tresorerie/historique-recettes",
        title: "Historique recette",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-HISTORIQUE-RECETTES",
        submenu: [],
      },
     
      
      {
        path: "/tresorerie/main-tresorerie/journal-caisse",
        title: "Journal de caisse",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-JOURNAL",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/point-caisse",
        title: "Journal principal",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-JOURNAL",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/point-remboursement",
        title: "Journal remboursement",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-JOURNAL-REMBOURSEMENT",
        submenu: [],
      },
      {
        path: "/tresorerie/main-tresorerie/reglement-echeancier",
        title: "Règlement écheancier",
        moduleName: "main-tresorerie",
        iconType: "list",
        icon: "plus",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "TRESO-REGLEMENT-ECHEANCIER",
        submenu: [],
      },
    ],
  },

  //Unite consultation
  {
    path: "",
    title: "Unité de consultation",
    moduleName: "unite-consultation",
    iconType: "stethoscope",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "UNITE-CONSULTATION-NAV",
    submenu: [
      {
        path: "/unite-consultation/archive",
        title: "Archives",
        moduleName: "unite-consultation",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-ARCHIVE",
        submenu: [],
      },
      {
        path: "/unite-consultation/salle-tri",
        title: "Prise de constante",
        moduleName: "unite-consultation",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-SALLE-TRI",
        submenu: [],
      },
      {
        path: "/unite-consultation/salle-tri-ophtalmo",
        title: "Prise de constante ophtalmo",
        moduleName: "unite-consultation",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-SALLE-TRI",
        submenu: [],
      },
      {
        path: "/unite-consultation/praticien",
        title: "Praticiens",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-PRATICIEN",
        submenu: [],
      },
      {
        path: "/unite-consultation/prescription-medecin-conseil",
        title: "Prescriptions",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-PRESCRIPTIONS",
        submenu: [],
      },
      {
        path: "/unite-consultation/praticien-consultation-en-cours/vw134Wxieidhhfng",
        title: "Consultations en cours",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-CONSULTATION-EN-COURS",
        submenu: [],
      },
      {
        path: "/unite-consultation/praticien-odonto",
        title: "Praticiens Odonto",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-PRATICIEN-ODONTO",
        submenu: [],
      },
      {
        path: "/unite-consultation/praticien-ophtalmo",
        title: "Praticiens Ophtalmo",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-PRATICIEN-OPHTALMO",
        submenu: [],
      },
      {
        path: "/unite-consultation/historique-consultation",
        title: "Historique consultation",
        moduleName: "unite-consultation",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-HISTO-CONSULTATION",
        submenu: [],
      },
      {
        path: "/unite-consultation/parcours-client",
        title: "Parcours client",
        moduleName: "unite-consultation",
        iconType: "exchange",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-VISUALISER-PARCOURS-CLIENT",
        submenu: [],
      },

      {
        path: "/unite-consultation/dialyse",
        title: "Dialyse",
        moduleName: "dialyse",
        iconType: "list",
        icon: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        class: "sub-menu-toggle",
        code: "UNITE-CONSULTATION-DIALYSE",
        submenu: [
          {
            path: "/unite-consultation/dialyse/historique-requete-dialyse",
            title: "Requêtes dialyse",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-REQUETE-DIALYSE",
            submenu: [],
          },
          {
            path: "/unite-consultation/dialyse/programme",
            title: "Programme",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-PROGRAMME",
            submenu: [],
          },
          {
            path: "/unite-consultation/dialyse/prescription",
            title: "Prescription",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-PROGRAMME",
            submenu: [],
          },
          {
            path: "/unite-consultation/dialyse/prescription-hebdo",
            title: "Prescritpion hebdo",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-PRESCRIPTION",
            submenu: [],
          }, 
          // {
          //   path: "/unite-consultation/dialyse/seance-dialyse",
          //   title: "Séance / Accueil",
          //   moduleName: "unite-consultation",
          //   iconType: "",
          //   icon: "",
          //   class: "ml-menu",
          //   groupTitle: false,
          //   badge: "",
          //   badgeClass: "",
          //   code: "71818545",
          //   submenu: [],
          // },
          {
            path: "/unite-consultation/dialyse/salle-tri-dialyse",
            title: "Prise de constante",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-SALLE-DE-TRI",
            submenu: [],
          },
          
          {
            path: "/unite-consultation/dialyse/branchement",
            title: "Branchement",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-BRANCHEMENT",
            submenu: [],
          },
          
          {
            path: "/unite-consultation/dialyse/debranchement",
            title: "Débranchement",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-DEBRANCHEMENT",
            submenu: [],
          },
          {
            path: "/unite-consultation/dialyse/cloture",
            title: "Clôture",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-CLOTURE",
            submenu: [],
          },
          {
            path: "/unite-consultation/dialyse/catographie-branchement",
            title: "Catographie branch.",
            moduleName: "dialyse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "UNITE-CONSULTATION-DIALYSE-CATOGRAPHIE-BRANCHEMENT",
            submenu: [],
          },
          

        ],
      },

      {
        path: "/unite-consultation/soin-ambulatoire",
        title: "Soin ambulatoire",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-SOIN-AMBULATOIRE",
        submenu: [],
      },

      {
        path: "/unite-consultation/exploration-fonctionnelle",
        title: "Exploration fonctionnelle",
        moduleName: "unite-consultation",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-EXPLORATION-FCT",
        submenu: [],
      },

      {
        path: "/unite-consultation/evacuations",
        title: "Evacuations",
        moduleName: "unite-consultation",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-EVACUATION",
        submenu: [],
      },

      {
        path: "/unite-consultation/certificat-medical",
        title: "Certificat médical",
        moduleName: "unite-consultation",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "UNITE-CONSULTATION-CERTIFICAT-MEDICAL-NAV",
        submenu: [],
      },

      {
        path: "/unite-consultation/consulter-planning",
        title: "Consulter planning",
        moduleName: "unite-consultation",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        // code: "60716507",
        submenu: [],
      },

    ],
  },

  // Prevention acl ok
  {
    path: "",
    title: "Prévention",
    moduleName: "prevention",
    iconType: "money-bill",
    icon: "monetization_on",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PREVENTION-NAV",
    submenu: [
    {
        path: "/prevention/campagne",
        title: "Campagne",
        moduleName: "campagne",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PREVENTION-CAMPAGNE",
        submenu: [

          {
            path: "/prevention/campagne/campagne-de-prevention",
            title: "Campagne de prevention",
            moduleName: "campagne",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PREVENTION-CAMPAGNE-PREVENTION",
            submenu: [],
          },
          {
            path: "/prevention/campagne/theme-de-prevention",
            title: "Theme de campagne",
            moduleName: "campagne",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PREVENTION-CAMPAGNE-THEME",
            submenu: [],
           },
        ],
      },
      {
        path: "/prevention/enquete",
        title: "Enquête",
        moduleName: "prevention",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PREVENTION-ENQUETE",
        submenu: [],
      },
     
      {
        path: "/prevention/mission",
        title: "Mission",
        moduleName: "prevention",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PREVENTION-MISSION",
        submenu: [],
      },
      {
        path: "/prevention/budget",
        title: "Budget",
        moduleName: "prevention",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PREVENTION-BUDGET",
        submenu: [],
      },
       {
        path: "/prevention/depistage",
        title: "Depistages",
        moduleName: "depistage",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PREVENTION-DEPISTAGE",
        submenu: [
          {
            path: "/prevention/depistage/entretien-medecin",
            title: "Entretien medecin",
            moduleName: "depistage",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PREVENTION-CAMPAGNE-PREVENTION",
            submenu: [],
          },
          {
            path: "/prevention/depistage/entretien-nutritionniste",
            title: "Entretient nutritionniste",
            moduleName: "depistage",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PREVENTION-CAMPAGNE-THEME",
            submenu: [],
           },
        ],
      },
      
    ]
  },

  // Gestion consommation ok acl
  {
    path: "",
    title: "Gestion consommation",
    moduleName: "gestion-consomation",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    iconType: "sitemap",
    badge: "",
    badgeClass: "",
    code: "GEST-CONSOMMATION-NAV",
    submenu: [
      // {
      //   path: "/gestion-consomation/livraison-produit",
      //   title: "Livraison produit",
      //   moduleName: "gestion-consomation",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "5316144",
      //   submenu: [],
      // },
      {
        path: "/gestion-consomation/stock-unit-fonc",
        title: "Stock",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-CONSOMMATION-STOCK",
        submenu: [],
      },
      {
        path: "/gestion-consomation/inventaire-stock-unit-fonct",
        title: "Inventaire stock",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-CONSOMMATION-INVENTAIRE",
        submenu: [],
      },
      {
        path: "/gestion-consomation/consomation-unit-fonct",
        title: "Consommation",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-CONSOMMATION-CONSOMMATION",
        submenu: [],
      },
      {
        path: "/gestion-consomation/demande-de-produit",
        title: "Demande de produit",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-CONSOMMATION-DEMANDE-PRODUIT",
        submenu: [],
      },
      {
        path: "/gestion-consomation/confirmer-reception",
        title: "Confirmer reception",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-CONSOMMATION-CONFIRMER-LIVRAISON",
        submenu: [],
      },
      {
        path: "/gestion-consomation/sortie-diverse-unit-fonct",
        title: "Sortie diverse",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "	GEST-CONSOMMATION-SORTIE-DIVERSE",
        submenu: [],
      },
      {
        path: "/gestion-consomation/mouvement-article",
        title: "Mouvement article",
        moduleName: "gestion-consomation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GEST-CONSOMMATION-MOUVEMENT-ARTICLE",
        submenu: [],
      },
      
    ],
  },

  // Gestion de la buanderie ok acl
  {
    path: "",
    title: "Gestion de la buanderie",
    moduleName: "gestion-buanderie",
    icon: "plus",
    class: "menu-toggle",
    groupTitle: false,
    iconType: "sitemap",
    badge: "",
    badgeClass: "",
    code: "BUANDERIE-NAV",
    submenu: [
      {
        path: "/gestion-buanderie/lingerie-hors-usage",
        title: "Lingerie hors d'usage",
        moduleName: "gestion-buanderie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "BUANDERIE-HORS-USAGE",
        submenu: [],
      },
      {
        path: "/gestion-buanderie/commande",
        title: "Commande",
        moduleName: "gestion-buanderie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "BUANDERIE-COMMANDE",
        submenu: [],
      },
      {
        path: "/gestion-buanderie/traitement-lingerie",
        title: "Traitement lingerie",
        moduleName: "gestion-buanderie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "BUANDERIE-TRAITEMENT-LINGERIE",
        submenu: [],
      }
    ],
  },

  // Labo ok acl
  {
    path: "/laboratoire",
    title: "Laboratoire",
    moduleName: "laboratoire",
    iconType: "procedures",
    icon: "procedures",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "LABO-NAV",
    submenu: [
      {
        path: "/laboratoire/prelevement",
        title: "Prelevement",
        moduleName: "prelevement",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "LABO-PRELEVEMENT",
        submenu: [
          {
            path: "/laboratoire/prelevement/actes-externes",
            title: "Actes externes",
            moduleName: "prelevement",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "LABO-PRELEVEMENT-AE",
            submenu: [],
          },
          {
            path: "/laboratoire/prelevement/hospitalisation",
            title: "Hospitalisation",
            moduleName: "prelevement",
            iconType: "list",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "LABO-PRELEVEMENT-HOSPI",
            submenu: [],
          }
        ],
      },
      {
        path: "/laboratoire/file-attente-resultat",
        title: "File d'attente résultat",
        moduleName: "laboratoire",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "LABO-FILE-ATTENTE-RESULTAT",
        submenu: [],
      },
      {
        path: "/laboratoire/interpretation-resultat",
        title: "Interprètation resultat",
        moduleName: "laboratoire",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "LABO-INTERPRETATION",
        submenu: [],
      },
      {
        path: "/laboratoire/distribution-actes-externe",
        title: "Distribution",
        moduleName: "laboratoire",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "LABO-DISTRIBUTION-AE",
        submenu: [],
      },

      // {
      //   path: "/laboratoire/distribution-hospi",
      //   title: "Distribution hospi",
      //   moduleName: "laboratoire",
      //   iconType: "ambulance",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "LABO-DISTRIBUTION-HOSPI",
      //   submenu: [],
      // },
      // {
      //   path: "/laboratoire/distribution-actes-externe-historique",
      //   title: "Historique resultat",
      //   moduleName: "laboratoire",
      //   iconType: "ambulance",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "LABO-CONSULTER-HISTORIQUE-RESULTAT",
      //   submenu: [],
      // },
      {
        path: "/laboratoire/edition-resultat",
        title: "Edition résultat",
        moduleName: "laboratoire",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "LABO-EDITION-RESULTAT",
        submenu: [],
      }

      
    ],
  },

  // Radio ok acl
  {
    path: "/radiologie/main-radiologie",
    title: "Radiologie",
    moduleName: "radiologie",
    iconType: "x-ray",
    icon: "x-ray",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "RADIO-NAV",
    submenu: [
      {
        path: "/radiologie/main-radiologie/file-attente",
        title: "Réception",
        moduleName: "radiologie",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-RECEPTION",
        submenu: [],
      },
      {
        path: "/radiologie/main-radiologie/resultat-radiologie",
        title: "File d'attente résultat",
        moduleName: "radiologie",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-FILE-ATTENTE-RESULTAT",
        submenu: [],
      },
      {
        path: "/radiologie/main-radiologie/interpretation-resultat",
        title: "Interprètation résultat",
        moduleName: "radiologie",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-INTERPRETATION",
        submenu: [],
      },
      {
        path: "/radiologie/main-radiologie/distribution-actes-externe",
        title: "Distribution actes externes",
        moduleName: "radiologie",
        iconType: "money",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-DISTRIBUTION-AE",
        submenu: [],
      },
      {
        path: "/radiologie/main-radiologie/distribution-hospi",
        title: "Distribution hospi",
        moduleName: "radiologie",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-DISTRIBUTION-HOSPI",
        submenu: [],
      }, {
        path: "/radiologie/main-radiologie/distribution-actes-externe-historique",
        title: "Historique resultat",
        moduleName: "radiologie",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-HISTORIQUE-RADIO",
        submenu: [],
      },
      , {
        path: "/radiologie/main-radiologie/edition-resultat",
        title: "Edition resultat",
        moduleName: "radiologie",
        iconType: "ambulance",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "RADIO-HISTORIQUE-RADIO",
        submenu: [],
      },
     
    ],
  },

  //Service technique ok acl
  {
    path: "",
    title: "Service technique",
    moduleName: "service-technique",
    iconType: "cog",
    icon: "dryer",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "SERVICE-TECHNIQUE-NAV",
    submenu: [
      {

        path: "/service-technique/equipement",
        title: "Gestion des equipements",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-EQUIPEMENT",
        submenu: [],
      },
      {

        path: "/service-technique/contrat",
        title: "Contrat d'entretien",
        moduleName: "service-technique",
        iconType: "wallet",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-CONTRAT",
        submenu: [],
      },
      {

        path: "/service-technique/execution",
        title: "Execution du contrat",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-EXECUTION-CONTRAT",
        submenu: [],
      },
      {

        path: "/service-technique/plan",
        title: "Plan d'entretien",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-PLAN-ENTRETIEN",
        submenu: [],
      },
      {

        path: "/service-technique/demande-intervention",
        title: "Demande d'intervention",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-PLAN-ENTRETIEN",
        submenu: [],
      },
      // {

      //   path: "/service-technique/expression-besoin",
      //   title: "Expression de besoin",
      //   moduleName: "service-technique",
      //   iconType: "list",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "SERVICE-TECHNIQUE-EXPRESSION-BESOIN",
      //   submenu: [],
      // },
      {

        path: "/service-technique/intervention",
        title: "Intervention",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-PLAN-ENTRETIEN",
        submenu: [],
      },
      {

        path: "/service-technique/bon-de-sortie",
        title: "Bon de sortie",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-PLAN-ENTRETIEN",
        submenu: [],
      },
      {

        path: "/service-technique/bon-de-reception",
        title: "Bon de reception",
        moduleName: "service-technique",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "SERVICE-TECHNIQUE-PLAN-ENTRETIEN",
        submenu: [],
      },

    ],
  },

  // Gestion commerciale
  // Acl a remplacer quand j'aurai le temps idem pour retour hospi en pharmacie
  {
    path: "",
    title: "Gestion Commerciale",
    moduleName: "gestion-commerciale",
    iconType: "sitemap",
    icon: "monetization_on",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "GEST-COMMERCIAL-NAV",
    submenu: [
      {
        path: "/gestion-commerciale/decompte-hospitalisation",
        title: "Decompte Hospitalisation",
        moduleName: "gestion-commerciale",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "DECOMPTE-HOSPIT",
        submenu: [],
      },
      {
        path: "/gestion-commerciale/demande-remise",
        title: "Demande de remise",
        moduleName: "gestion-commerciale",
        iconType: "list",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "DEMANDE-REMISE",
        submenu: [],
      },
      {
        path: "/gestion-commerciale/facturation",
        title: "Facturation",
        moduleName: "facturation",
        iconType: "money-bill-alt",
        icon: "",
        code: "FACTURATION",
        class: "sub-menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/gestion-commerciale/facturation/facture-acte-externe",
            title: "Facture acte externe",
            moduleName: "facture-acte-externe",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "FACTURE-ACTES-EXTERNES",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/facturation/facture-hospi",
            title: "Facture hospi",
            moduleName: "facture-hospi",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "FACTURES-HOSPI",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/facturation/facture-dialyse",
            title: " Facture dialyse",
            moduleName: "facture-dialyse",
            iconType: "money-bill-alt",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "FACTURES-DIALYSE",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/facturation/facture-pharmacie",
            title: "Facture pharmacie",
            moduleName: "facture-pharmacie",
            iconType: "receipt",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "FACTURE-PHARMACIE",
            submenu: [],
          },
        ],
      },
      {
        path: "/gestion-commerciale/echeance",
        title: "Echeance",
        moduleName: "echeance",
        iconType: "money-bill-alt",
        icon: "",
        code: "ECHEANCE",
        class: "sub-menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/gestion-commerciale/echeance/echeance-acte-externe",
            title: "Echeance acte externe",
            moduleName: "echeance-acte-externe",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "ECHEANCE-ACTES-EXTERNES",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/echeance/echeance-decompte-hospi",
            title: "Echeance hospi",
            moduleName: "echeance-decompte-hospi",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "ECHEANCE-HOSPI",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/echeance/echeance-facture-dialise",
            title: " Echeance dialyse",
            moduleName: "echeance-facture-dialise",
            iconType: "money-bill-alt",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "ECHEANCE-DIALYSE",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/echeance/echeance-vente-pharmacie-vaext",
            title: "Echeance pharmacie VE",
            moduleName: "echeance-vente-pharmacie-vd",
            iconType: "receipt",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "ECHEANCE-PHARMACIE-VE",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/echeance/echeance-vente-pharmacie-vd",
            title: "Echeance pharmacie VD",
            moduleName: "echeance-vente-pharmacie-vd",
            iconType: "receipt",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "ECHEANCE-PHARMACIE-VENTE-DIRECTE",
            submenu: [],
          },

          // {
          //   path: "/gestion-commerciale/echeance/echeance-vente-pharmacie-aexterne",
          //   title: "Echeance pharmacie V AE",
          //   moduleName: "echeance-vente-pharmacie-aexterne",
          //   iconType: "wallet",
          //   icon: "",
          //   class: "ml-menu",
          //   groupTitle: false,
          //   badge: "",
          //   badgeClass: "",
          //   code: "7653497",
          //   submenu: [],
          // },
        ],
      },
      {
        path: "/gestion-commerciale/bordereau-facture",
        title: "Bordereau de facture",
        moduleName: "bordereau-facture",
        iconType: "money-bill-alt",
        icon: "",
        code: "BORDEREAU",
        class: "sub-menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/gestion-commerciale/bordereau-facture/acte-externe",
            title: "Actes externes",
            moduleName: "bordereau-facture",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BORDEREAU-ACTES-EXTERNES",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/bordereau-facture/pharmacie",
            title: "Pharmacie",
            moduleName: "bordereau-facture",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BORDEREAU-PHARMACIE",
            submenu: [],
          },
          {
            path: "/gestion-commerciale/bordereau-facture/hospi-meo",
            title: "Hospi/MEO",
            moduleName: "bordereau-facture",
            iconType: "wallet",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "BORDERAU-HOSPI-MEO",
            submenu: [],
          },
          
        ],
      },
      // {
      //   path: "/gestion-commerciale/bordereau",
      //   title: "Bordereau",
      //   moduleName: "bordereau",
      //   iconType: "money-bill-alt",
      //   icon: "",
      //   code: "75447518",
      //   class: "sub-menu-toggle",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   submenu: [
      //     {
      //       path: "/gestion-commerciale/bordereau/bordereau-acte-externe",
      //       title: "Bordereau acte externe",
      //       moduleName: "bordereau-acte-externe",
      //       iconType: "wallet",
      //       icon: "",
      //       class: "ml-menu",
      //       groupTitle: false,
      //       badge: "",
      //       badgeClass: "",
      //       code: "GEST-COMMERCIAL-NAV",
      //       submenu: [],
      //     },
      //     {
      //       path: "/gestion-commerciale/bordereau/bordereau-hospi",
      //       title: "Bordereau hospi",
      //       moduleName: "bordereau-hospi",
      //       iconType: "wallet",
      //       icon: "",
      //       class: "ml-menu",
      //       groupTitle: false,
      //       badge: "",
      //       badgeClass: "",
      //       code: "GEST-COMMERCIAL-NAV",
      //       submenu: [],
      //     },
      //     {
      //       path: "/gestion-commerciale/bordereau/bordereau-pharmacie",
      //       title: "Bordereau pharmacie",
      //       moduleName: "bordereau-pharmacie",
      //       iconType: "money-bill-alt",
      //       icon: "",
      //       class: "ml-menu",
      //       groupTitle: false,
      //       badge: "",
      //       badgeClass: "",
      //       code: "GEST-COMMERCIAL-NAV",
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
  
  // Pharmacie ok
  {
    path: "",
    title: "Pharmacie",
    moduleName: "pharmacie",
    iconType: "hospital",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PHARMACIE-NAV",
    submenu: [
      

      {
        path: "/pharmacie/rupture-stock",
        title: "Rupture de stock",
        moduleName: "pharmacie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-RUPTURE-STOCK",
        submenu: [],
      },
      {
        path: "/pharmacie/commande-medicament-pharmacie",
        title: "Commande médicament",
        moduleName: "pharmacie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-COMMANDE-MEDOC",
        submenu: [],
      },
      {
        path: "/pharmacie/livraison-medicament-pharmacie",
        title: "Livraison médicament",
        moduleName: "pharmacie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-LIVRAISON-MEDICAMENT",
        submenu: [],
      },
      {
        path: "/pharmacie/commande-de-sang",
        title: "Commande de sang",
        moduleName: "pharmacie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-COMMANDE-SANG",
        submenu: [],
      },
      // {
      //   path: "/pharmacie/livraison-sang",
      //   title: "Livraison de sang",
      //   moduleName: "pharmacie",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "PHARMACIE-LIVRAISON-SANG",
      //   submenu: [],
      // },

      {
        path: "/pharmacie/retour-fournisseur",
        title: "Rétour fournisseur",
        moduleName: "retour-fournisseur",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-RETOUR-FOURNISSEUR",
        submenu: [],
      },

      {
        path: "/pharmacie/gestion-de-stock",
        title: "Gestion de stock",
        moduleName: "gestion-de-stock",
        iconType: "",
        icon: "",
        // class: "ml-menu",
        code: "PHARMACIE-GESTION-STOCK",
        class: "sub-menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/pharmacie/gestion-de-stock/initialiser-magasin",
            title: "Initialisation magasin",
            moduleName: "pharmacie",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "INITIALISATION_MAGASIN",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/mise-a-jour-seuil",
            title: "Maj. seuils",
            moduleName: "pharmacie",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "MISE_A_JOUR_SEUIL",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/calcul-seuil-alerte",
            title: "Calcul seuil alerte",
            moduleName: "pharmacie",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "CALCUL_SEUIL_ALERTE",
            submenu: [],
          },
          
          {
            path: "/pharmacie/gestion-de-stock/catalogue-fournisseur",
            title: "Catalogue fournisseur",
            moduleName: "catalogue-fournisseur",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-GEST-STOCK-CATALOGUE",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/rangement-medicament",
            title: "Ranger medicament",
            moduleName: "rangement-medicament",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-GEST-STOCK-RANGEMENT-MEDICAMENT",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/sortiee-diverse",
            title: "Sortie diverse",
            moduleName: "sortiee-diverse",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-GEST-STOCK-SORTIE-DIVERSE",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/transfert-medicament",
            title: "Transfert medicament",
            moduleName: "transfert-medicament",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-GEST-STOCK-TRANSFERT",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/consultation-stock",
            title: "Consultation stock",
            moduleName: "consultation-stock",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-GEST-STOCK-CONSULTATION-STOCK",
            submenu: [],
          },
          {
            path: "/pharmacie/gestion-de-stock/mouvement-article",
            title: "Mouvement article",
            moduleName: "mouvement-article",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-GEST-STOCK-MVT-ARTICLE",
            submenu: [],
          }
        ],
      },

      {
        path: "/pharmacie/inventaire-de-stock",
        title: "Inventaire de stock",
        moduleName: "pharmacie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-INVENTAIRE",
        submenu: [],
      },

      {
        path: "/pharmacie/vente-medicaments",
        title: "Ventes",
        moduleName: "vente-medicaments",
        iconType: "",
        icon: "",
        // class: "ml-menu",
        code: "PHARMACIE-VENTE-MEDICAMENT",
        class: "sub-menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/pharmacie/vente-medicaments/vente-directe",
            title: "Ventes directes",
            moduleName: "vente-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-VENTE-VD",
            submenu: [],
          },
          {
            path: "/pharmacie/vente-medicaments/vente-sur-bon",
            title: "Vente sur bon",
            moduleName: "vente-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "VENTE_SUR_BON",
            submenu: [],
          },
          {
            path: "/pharmacie/vente-medicaments/vente-acte-externes",
            title: "Vente prescription",
            moduleName: "vente-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-VENTE-AE",
            submenu: [],
          },
          {
            path: "/pharmacie/vente-medicaments/vente-hospi",
            title: "Hospitalisation",
            moduleName: "vente-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-VENTE-HOSPI",
            submenu: [],
          },
        ],
      },
      {
        path: "/pharmacie/retour-medicaments",
        title: "Retour médicaments",
        moduleName: "retour-medicaments",
        iconType: "",
        icon: "",
        // class: "ml-menu",
        code: "PHARMACIE-RETOUR",
        class: "sub-menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/pharmacie/retour-medicaments/vente-directe",
            title: "Ventes directes",
            moduleName: "retour-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-RETOUR-VENTE-DIRECTE",
            submenu: [],
          },
          {
            path: "/pharmacie/retour-medicaments/vente-bon",
            title: "Ventes sur bon",
            moduleName: "retour-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-RETOUR-VENTE-DIRECTE",
            submenu: [],
          },
          {
            path: "/pharmacie/retour-medicaments/vente-acte-externes",
            title: "Vente prescription",
            moduleName: "retour-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "PHARMACIE-RETOUR-ACTE-EXTERNE",
            submenu: [],
          },
          {
            path: "/pharmacie/retour-medicaments/vente-hospi",
            title: "Hospitalisation",
            moduleName: "retour-medicaments",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            // code: "PHARMACIE-RETOUR-HOSPI",
            code: "PHARMACIE-RETOUR-ACTE-EXTERNE",
            submenu: [],
          },
        ],
      },

      {
        path: "/pharmacie/livraison-services",
        title: "Livraison aux services",
        moduleName: "pharmacie",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PHARMACIE-LIVRAISON-AUX-SERVICE",
        submenu: [],
      },
    ],
  },

  // Hospi acl ok
  {
    path: "",
    title: "Unité de soins",
    moduleName: "hospitalisation",
    iconType: "hospital-alt",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "HOSPI-NAV",
    submenu: [
      // {
      //   path: "/hospitalisation/demande-hospi",
      //   title: "Création dossier",
      //   moduleName: "demande hospi",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "HOSPI-CREATE-DOSSIER",
      //   submenu: [],
      // },
      {
        path: "/hospitalisation/modification-demande",
        title: "Modification demande",
        moduleName: "demande hospi",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-MODIFIER-DEMANDE",
        submenu: [],
      },
      {
        path: "/hospitalisation/fiche-engagement",
        title: "Fiche engagement",
        moduleName: "fiche-engagement",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-FICHE-ENGAGEMENT",
        submenu: [],
      },
      {
        path: "/hospitalisation/dossier-hospi",
        title: "Dossier hospi",
        moduleName: "dossier hospi",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-DOSSIER-HOSPI",
        submenu: [],
      },


      {
        path: "/hospitalisation/attribution-lit",
        title: "Attribution lit",
        moduleName: "attribution-lit",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-ATTRIBUTION-LIT",
        submenu: [],
      },

      {
        path: "/hospitalisation/preparation-lit",
        title: "Préparation-lit",
        moduleName: "preparation-lit",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-PREPARATION-LIT",
        submenu: [],
      },
      {
        path: "/hospitalisation/commande",
        title: "Commande",
        moduleName: "commande",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-COMMANDE",
        submenu: [
          {
            path: "/hospitalisation/commande/medicament",
            title: "Médicaments",
            moduleName: "commande",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "HOSPI-COMMANDE-MEDICAMENT",
            submenu: [],
          },
          {
            path: "/hospitalisation/commande/sang",
            title: "sang",
            moduleName: "commande",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "HOSPI-COMMANDE-SANG",
            submenu: [],
          }
        ],
      },

      {
        path: "/hospitalisation/execution-soins",
        title: "Execution soins",
        moduleName: "execution-soins",
        iconType: "",
        icon: "",
        // class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-EXECUTION-SOIN",
        class: "sub-menu-toggle",
        submenu: [
          {
            path: "/hospitalisation/execution-soins/soins-infirmier",
            title: "Soins infirmier",
            moduleName: "execution-soins",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "HOSPI-EXECUTION-SOIN-INFIRMIER",
            submenu: [],
          },
          {
            path: "/hospitalisation/execution-soins/prise-de-constante",
            title: "Prise de constante",
            moduleName: "execution-soins",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "HOSPI-EXECUTION-PRISE-CONSTANTE",
            submenu: [],
          },
          {
            path: "/hospitalisation/execution-soins/transfusion-sanguine",
            title: "Transfusion sanguine",
            moduleName: "execution-soins",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "HOSPI-EXECUTION-TRANSFUSION-SANGUINE",
            submenu: [],
          },
          {
            path: "/hospitalisation/execution-soins/toilette-malade",
            title: "Toilette malade",
            moduleName: "execution-soins",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            code: "HOSPI-EXECUTION-TOILLETTE",
            submenu: [],
          },

        ],
      },
      {
        path: "/hospitalisation/visite-medecin",
        title: "Visite medecin",
        moduleName: "visite-medecin",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-VISITE",
        submenu: [],
      },
      {
        path: "/hospitalisation/demande-de-sortie",
        title: "Demande de sortie",
        moduleName: "hospitalisation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        // code: "HOSPI-DEMANDE-SORTIE",
        submenu: [],
      },
      // {
      //   path: "/hospitalisation/decompte",
      //   title: "Décompte",
      //   moduleName: "hospitalisation",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "13043680",
      //   submenu: [],
      // },
      {
        path: "/hospitalisation/bon-de-sortie",
        title: "Bon de sortie",
        moduleName: "hospitalisation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "HOSPI-BON-DE-SORTIE",
        submenu: [],
      },


      // {
      //   path: "/hospitalisation/historiques",
      //   title: "Historiques",
      //   moduleName: "hospitalisation",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code:'7653497',
      //   submenu: [],
      // },
    ],
  },

  // Parametre acl ok
  {
    path: "",
    title: "Paramètres",
    moduleName: "parametres",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/parametres/administratif",
        title: "Administratifs",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/parametres/bureau-entrees",
        title: "Bureau des entrées",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-BUREAU-ENTRESS",
        submenu: [],
      },
      {
        path: "/parametres/gestion-commercial",
        title: "Gestion commercial",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-GESTION-COMMERCIALE",
        submenu: [],
      },
      {
        path: "/parametres/unite-de-soin",
        title: "Unité de soins",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-UNITE-SOIN",
        submenu: [],
      },
      {
        path: "/parametres/pharmacie",
        title: "Pharmacie",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-PHARMACIE",
        submenu: [],
      },
      {
        path: "/parametres/unite-de-consultation",
        title: "Unité de consultation",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-UNITE-CONSULTATION",
        submenu: [],
      },
      {
        path: "/parametres/dialyse",
        title: "Dialyse",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-DIALYSE",
        submenu: [],
      },
      {
        path: "/parametres/ophtalmo",
        title: "Ophtalmo",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-OPTHALMO",
        submenu: [],
      },
      {
        path: "/parametres/laboratoire",
        title: "Laboratoire",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-LABORATOIRE",
        submenu: [],
      },
      {
        path: "/parametres/prevention",
        title: "Prévention",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-PREVENTION",
        submenu: [],
      },
      {
        path: "/parametres/service-technique",
        title: "Service Technique",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-SERVICE-TECHNIQUE",
        submenu: [],
      },
    ],
  },

  // Administration acl ok
  {
    path: "",
    title: "Administration",
    moduleName: "administration",
    iconType: "lock",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "ADMIN-NAV",
    submenu: [
      {
        path: "/administration/utilisateurs",
        title: "Utilisateurs",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-USERS",
        submenu: [],
      },
      {
        path: "/administration/roles",
        title: "Roles",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-ROLE",
        submenu: [],
      },
      {
        path: "/administration/circuit-validation",
        title: "Circuit validation",
        moduleName: "circuit-validation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-CIRCUIT-VALIDATION",
        submenu: [],
      },
      {
        path: "/administration/fonctionnalites",
        title: "Fonctionnalites",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        // code: "73166694",
        submenu: [],
      },
      {
        path: "/administration/historique-connexion",
        title: "Historique connexion",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-HISTORIQUE-CONNEXION",
        submenu: [],
      },

    ],
  },
];
